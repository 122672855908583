import * as React from "react";
import { FunctionComponent, RefObject, useEffect, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { IResponseModelState } from "../../../ts/models/response-model-state";
import { ICustomer } from "../../../ts/models/customer";
import { validatePostcode } from "../../../ts/functions/form-validation";
import { countryList } from "../../../ts/constants/form-values";

interface IProps {
	editAddress: ICommercetoolsAddress;
	toggleForm: Function;
	onFormSubmit: Function;
	modelStateErrors?: IResponseModelState[];
	customer?: ICustomer;
}

export const BillingAddressForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors, watch } = useForm({
		defaultValues: {
			company: props.editAddress?.Company,
			Addition1: props.editAddress?.Addition1,
			Addition2: props.editAddress?.Addition2,
			department: props.editAddress?.Department,
			industry: props.editAddress?.Industry,
			salutation: props.editAddress?.Salutation,
			title: props.editAddress?.Title,
			firstName: props.editAddress?.FirstName,
			lastName: props.editAddress?.LastName,
			additionalInfo: props.editAddress?.AdditionalInfo,
			streetName: props.editAddress?.StreetName,
			streetNumber: props.editAddress?.StreetNumber,
			postalCode: props.editAddress?.PostalCode,
			city: props.editAddress?.City,
			country: props.editAddress?.Country,
			vatNo: props.editAddress?.VatNo
		}
	});

	const [currentEditBillingAddress, setcurrentEditBillingAddress] = useState({
		...props.editAddress
	});

	const [plzError, setPlZError] = useState(false);
	const [showVatField, setShowVatField] = useState(false);

	const country = watch("country");
	const [modelStateErrors, setModelStateErrors] = useState(props.modelStateErrors);

	const validatePlz = (value: string) => {
		const isValid = validatePostcode(value, country);
		setPlZError(!isValid);
		return isValid;
	};

	function onSubmit(data: Record<string, any>) {
		props.onFormSubmit(data);
	}

	useEffect(() => {
		setModelStateErrors(props.modelStateErrors);
	}, [props.modelStateErrors]);

	useEffect(() => {
		const currentCountry = country ?? currentEditBillingAddress?.Country;
		if (currentCountry !== undefined) {
			setShowVatField(countryList.find((x) => x.value === currentCountry)?.vatNeeded ?? false);
		}
	}, [country]);

	function CreateApiError(key: string) {
		const error = modelStateErrors?.find((x) => x.Key === key);
		if (error !== undefined && error !== null) {
			return <div className="form-error">{error.Value[0]}</div>;
		}
	}

	function clearApiErrors() {
		setModelStateErrors([]);
	}

	return (
		<>
			<form aria-labelledby="headline_new" onSubmit={handleSubmit(onSubmit)}>
				<div className="address__form">
					<div className="row">
						<div className="col-12">
							{currentEditBillingAddress.Id && (
								<h2 className="heading">Rechnungsadresse bearbeiten</h2>
							)}
							{!currentEditBillingAddress.Id && (
								<h2 id="headline_new" className="heading">
									Neue Rechnungsadresse anlegen
								</h2>
							)}
							<hr />
							<legend>
								Bitte füllen Sie alle mit <span className="mandatory">*</span> gekennzeichneten
								Felder aus.
							</legend>
						</div>
						<div className="col-sm-12">
							<div className="form-group row">
								<label htmlFor="company_input_billing" className="col-sm-4 col-form-label">
									Firma / Name des Selbstständigen
									<span className="mandatory">*</span>{" "}
									<span id="company_billing_explanation" className="form-hint">
										<br />
										(Bitte tragen Sie bei selbstständiger Tätigkeit hier die Firmierung / Ihren
										Namen, z.B. Rechtsanwältin Erika Musterfrau, ein.)
									</span>
								</label>
								<div className="col-sm-8">
									<input
										value={currentEditBillingAddress.Id}
										ref={register()}
										type="text"
										name="id"
										hidden
									/>
									<input
										type="text"
										id="company_input_billing"
										aria-describedby="company_billing_explanation"
										name="company"
										className="form-control"
										placeholder="Firmenname"
										aria-required="true"
										maxLength={35}
										ref={register({ required: true })}
									/>
									{errors?.company && (
										<div className="form-error">Bitte geben Sie Ihren Firmennamen ein.</div>
									)}
									{CreateApiError("data.Company")}
									<input
										type="text"
										name="Addition1"
										aria-label="Firmenzusatz1"
										className="form-control mt-3"
										placeholder="Firmenzusatz1"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Addition1")}
									<input
										type="text"
										name="Addition2"
										aria-label="Firmenzusatz2"
										className="form-control mt-3"
										placeholder="Firmenzusatz2"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Addition2")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="department_input_billing" className="col-sm-4 col-form-label">
									Abteilung
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="department"
										id="department_input_billing"
										className="form-control"
										placeholder="Abteilung"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Department")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="salutation_input_billing" className="col-sm-4 col-form-label">
									Anrede
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										id="salutation_input_billing"
										name="salutation"
										className="form-control"
										ref={register()}
									>
										<option value="" />
										<option value="F">Frau</option>
										<option value="M">Herr</option>
										<option value="D">Divers</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="title_input_billing" className="col-sm-4 col-form-label">
									Titel
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="title"
										className="form-control"
										id="title_input_billing"
										placeholder="Titel"
										ref={register()}
									>
										<option />
										<option>Dr.</option>
										<option>Prof.</option>
										<option>Prof. Dr.</option>
										<option>LL. M.</option>
										<option>Ass. Jur.</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="firstname_input_billing" className="col-sm-4 col-form-label">
									Vorname
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										id="firstname_input_billing"
										name="firstName"
										className="form-control"
										placeholder="Vorname"
										maxLength={30}
										ref={register({ maxLength: 30 })}
									/>
									{errors?.firstName && (
										<div className="form-error">Der Vorname darf maximal 30 Zeichen lang sein.</div>
									)}
									{CreateApiError("data.FirstName")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="lastname_input_billing" className="col-sm-4 col-form-label">
									Nachname
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="lastName"
										id="lastname_input_billing"
										className="form-control"
										placeholder="Nachname"
										maxLength={30}
										ref={register({ maxLength: 30 })}
									/>
									{errors?.lastName && (
										<div className="form-error">
											Der Nachname darf maximal 30 Zeichen lang sein.
										</div>
									)}
									{CreateApiError("data.LastName")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="addressadition_input_billing" className="col-sm-4 col-form-label">
									Adresszusatz
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										id="addressadition_input_billing"
										name="additionalInfo"
										className="form-control"
										placeholder="Adresszusatz"
										maxLength={35}
										ref={register({ maxLength: 35 })}
									/>
									{CreateApiError("data.AdditionalInfo")}
								</div>
							</div>
							<div className="form-group row">
								<div className="col-sm-4 col-form-label">
									<label htmlFor="street_input_billing" className="col-form-label">
										Straße /&nbsp;
									</label>
									<label htmlFor="streetnumber_input_billing" className="col-form-label">
										Hausnummer <span className="mandatory">*</span>
									</label>
								</div>
								<div className="col-sm-5 col-xs-1 mobile-space">
									<input
										type="text"
										name="streetName"
										id="street_input_billing"
										className="form-control"
										placeholder="Straße"
										maxLength={46}
										aria-required="true"
										ref={register({ required: true, maxLength: 46 })}
									/>
									{errors?.streetName && (
										<div className="form-error">Bitte geben Sie die Straße ein.</div>
									)}
									{CreateApiError("data.StreetName")}
								</div>
								<div className="col-sm-3 col-xs-1 mobile-space">
									<input
										type="text"
										id="streetnumber_input_billing"
										name="streetNumber"
										className="form-control"
										placeholder="Hausnummer"
										aria-required="true"
										ref={register({ required: true })}
									/>
									{errors?.streetNumber && (
										<div className="form-error">Bitte geben Sie die Hausnummer ein.</div>
									)}
									{CreateApiError("data.StreetNumber")}
								</div>
							</div>
							<div className="form-group row">
								<div className="col-sm-4">
									<label htmlFor="plz_input_billing" className="col-form-label">
										PLZ /&nbsp;
									</label>
									<label htmlFor="city_input_billing" className="col-form-label">
										Ort<span className="mandatory">*</span>
									</label>
								</div>
								<div className="col-sm-3 col-xs-1 mobile-space">
									<input
										type="text"
										name="postalCode"
										id="plz_input_billing"
										className="form-control"
										placeholder="PLZ"
										aria-required="true"
										ref={register({ required: true, validate: validatePlz })}
									/>
									{errors?.postalCode && (
										<div className="form-error">Bitte geben Sie eine valide PLZ ein.</div>
									)}
									{CreateApiError("data.PostalCode")}
								</div>
								<div className="col-sm-5 col-xs-1 mobile-space">
									<input
										type="text"
										name="city"
										id="city_input_billing"
										className="form-control"
										placeholder="Ort"
										aria-required="true"
										ref={register({ required: true })}
									/>
									{errors?.city && <div className="form-error">Bitte geben Sie den Ort ein.</div>}
									{CreateApiError("data.City")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="country_input_billing" className="col-sm-4 col-form-label">
									Land: <span className="mandatory">*</span>
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="country"
										className="form-control"
										id="country_input_billing"
										placeholder=""
										aria-required="true"
										ref={register({ required: true })}
									>
										{countryList.map((countryItem) => (
											<option value={countryItem.value} key={countryItem.value}>
												{countryItem.name}
											</option>
										))}
									</select>
									{errors?.country && (
										<div className="form-error">Bitte wählen Sie Ihr Land aus.</div>
									)}
								</div>
							</div>
							{showVatField && (
								<div className="form-group row">
									<label className="col-sm-4 col-form-label">
										VAT <span className="mandatory">*</span>
									</label>
									<div className="col-sm-8">
										<input
											type="text"
											name="vatNo"
											className="form-control"
											placeholder="VAT"
											maxLength={35}
											ref={register({ required: true })}
										/>
										{errors?.vatNo && (
											<div className="form-error">Bitte geben Sie die VAT ein.</div>
										)}
										{CreateApiError("VatNo")}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-4" />
						<div className="col-12 col-md-8 text-right">
							<button
								className="button button__cta button--grey order__button mr-3 d-none d-md-inline-block"
								onClick={() => props.toggleForm()}
								type="button"
							>
								<span className="">Abbrechen</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
							<button
								className="button button__cta button--red order__button"
								onClick={clearApiErrors}
								type="submit"
							>
								<span className="">
									{props.editAddress != null ? "Speichern" : "Neue Adresse anlegen"}
								</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
							<button
								className="button button__cta button--grey order__button mr-3 d-inline-block d-md-none"
								onClick={() => props.toggleForm()}
								type="button"
							>
								<span className="">Abbrechen</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};
