import * as React from "react";
import { FunctionComponent, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import axios from "axios";
import { HideSpinner, ShowSpinner } from "../../../ts/components/spinner";
import { IFormError } from "../../../ts/models/error/form-error";
import { ShowPopupError } from "../../../ts/components/error-popup";
import * as UserCentrics from "../../../ts/shared/user-centrics";
import { checkoutLoginTracking } from "../../../ts/functions/google-tracking";

declare let grecaptcha: any;

interface IProps {
	successRedirectUrl: string;
	resetPasswordUrl: string;
	SiteKey: string;
	isCheckout: boolean;
}

export const LoginForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors } = useForm();
	const [persistentLogin, setPersistentLogin] = useState(false);

	function postData(data: Record<string, any>) {
		axios
			.post(`/umbraco/api/auth/login`, data)
			.then(() => {
				checkoutLoginTracking(true);
				window.location.href = props.successRedirectUrl;
				HideSpinner();
			})
			.catch((error: any) => {
				if (error?.response?.data) {
					const object = JSON.parse(error?.response?.data?.Message) as IFormError;
					if (object.ErrorMessage) {
						ShowPopupError(object.ErrorMessage);
					}
				}
				checkoutLoginTracking(false);
				HideSpinner();
			});
	}

	function onSubmit(data: Record<string, any>) {
		if (!UserCentrics.IsRecaptchaAllowed()) {
			return;
		}

		ShowSpinner();

		grecaptcha.ready(function() {
			grecaptcha
				.execute(props.SiteKey, { action: "submit" })
				.then(function(token: string) {
					if (token === "") {
						HideSpinner();
						return;
					}

					postData(data);
				})

				.catch(function() {
					HideSpinner();
				});
		});
	}

	return (
		<>
			<span className="heading">Bereits Registriert?</span>
			<p>
				Loggen Sie sich bequem mit Ihrem Account von unseren Beck-Plattformen (beck-shop.de,
				beck-online.de, chbeck.de, vahlen.de, beck-stellenmarkt.de oder beck-seminare.de) ein:
			</p>
			<form aria-label="Login" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-group row">
					<label htmlFor="user_input" className="col-sm-4 col-form-label">
						E-Mail / Benutzername
					</label>
					<div className="col-sm-8">
						<input
							id="user_input"
							type="text"
							className="form-control"
							name="user"
							maxLength={60}
							placeholder="E-Mail"
							aria-required="true"
							ref={register({ required: true })}
						/>
						{errors.user && <div className="form-error">Bitte geben Sie den Benutzernamen ein</div>}
					</div>
				</div>
				<div className="form-group row">
					<label htmlFor="password_input" className="col-sm-4 col-form-label">
						Passwort
					</label>
					<div className="col-sm-8">
						<input
							id="password_input"
							type="password"
							className="form-control"
							name="password"
							placeholder="Password"
							aria-required="true"
							ref={register({ required: true })}
						/>
						{errors.password && <div className="form-error">Bitte geben Sie das Passwort ein</div>}
					</div>
				</div>
				<div />
				<div className="row hint">
					<div className="offset-md-4 col-md-4 col-xs-12">
						<div className="check">
							<span
								className={`check__box check__elm billing__checkbox ${
									persistentLogin ? "checked" : ""
								}`}
								onClick={() => setPersistentLogin(!persistentLogin)}
							/>
							<span className="check__elm checkbox__label">Angemeldet bleiben</span>
							<input
								type="hidden"
								name="persistentLogin"
								ref={register()}
								value={String(persistentLogin)}
							/>
						</div>
					</div>
					<div className="col-md-4 col-xs-12 text-right">
						<a href="/meine-daten/passwort-vergessen">Passwort vergessen?</a>
					</div>
				</div>

				<div className="row pt-2 gRecaptchaWrapper" style={{ display: "none" }}>
					<div className="offset-4 col-md-8">
						<div className="gRecaptcha" />
					</div>
				</div>

				<div className="row pt-2">
					<div className="offset-md-4 col-md-6 col-xs-12">
						<button type="submit" className="button button__cta button--red">
							<span className="txt">Anmelden</span>
							<i className="fas fa-arrow-right icon-fa icon--white" />
						</button>
					</div>
				</div>
			</form>
		</>
	);
};
