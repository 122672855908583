import * as React from "react";
import { FunctionComponent, RefObject, useState } from "react";
import { ICommercetoolsAddress } from "../../../ts/models/billing-address";
import { AddressBox } from "./address-box";
import { BillingAddressForm } from "./billing-address-form";
import { AddButton } from "../../shared/checkout/add-button";
import { IResponseModelState } from "../../../ts/models/response-model-state";
import { ICustomer } from "../../../ts/models/customer";

interface IProps {
	billingAddresses: ICommercetoolsAddress[];
	onSelectBillingAddress: Function;
	currentAddres: string;
	onFormSubmit: Function;
	showBillingForm: boolean;
	toggleForm: Function;
	onRemoveAddress: Function;
	onEditAddress: Function;
	editAddress: ICommercetoolsAddress;
	addresBoxLarge: boolean;
	showRadioButton: boolean;
	modelStateErrors?: IResponseModelState[];
	customer?: ICustomer;
	isPurchaser?: boolean;
	isEmployee?: boolean;
	isOnlineCustomer?: boolean;
	scrollTo: RefObject<HTMLDivElement>;
}

export const BillingAddresses: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<>
			<div className="row">
				<div className="col-12">
					<div className="billing">
						<h2 className="heading">Rechnungsadressen</h2>
					</div>
				</div>
			</div>
			<section aria-label="Vorhandene Rechnungsadressen" className="row billing__boxes">
				{props.isOnlineCustomer && (
					<div className="validation container beckonline-info">
						<div className="col-12 border border-danger">
							<p className="text__module">
								Da Ihr Account mit einem beck-online-Account verknüpft ist, können
								Rechnungsadressdaten nicht verändert oder hinzugefügt werden. Sollten Sie eine
								andere Rechnungsadresse für Ihre Buchung verwenden wollen, ist eine Anmeldung nur
								per E-Mail mit dem Betreff „beck-online-Kunde" an{" "}
								<a href="mailto:seminare@beck.de">seminare@beck.de</a> möglich. Vielen Dank für Ihre
								Unterstützung.
							</p>
						</div>
					</div>
				)}

				{props.billingAddresses.map((address: ICommercetoolsAddress) => (
					<AddressBox
						key={address.Id}
						address={address}
						onRemoveAddress={props.onRemoveAddress}
						onEditAddress={props.onEditAddress}
						type="billingaddress"
						onSelect={props.onSelectBillingAddress}
						currentAddress={props.currentAddres}
						title="Rechnungsadresse"
						addresBoxLarge={props.addresBoxLarge}
						showRadioButton={props.showRadioButton}
						isOnlineCustomer={props.isOnlineCustomer}
						isPurchaser={props.isPurchaser}
						isEmployee={props.isEmployee}
					/>
				))}
			</section>

			{!props.showBillingForm &&
				!props.isOnlineCustomer &&
				!props.isEmployee &&
				!props.isPurchaser && (
					<div className="row">
						<div className="col-12">
							<AddButton buttonText="Rechnungsadresse hinzufügen" onClick={props.toggleForm} />
						</div>
					</div>
				)}
			<div ref={props.scrollTo} />
			{props.showBillingForm && (
				<BillingAddressForm
					editAddress={props.editAddress}
					modelStateErrors={props.modelStateErrors}
					toggleForm={props.toggleForm}
					onFormSubmit={props.onFormSubmit}
					customer={props.customer}
				/>
			)}
		</>
	);
};
