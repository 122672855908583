import * as React from "react"
import { FunctionComponent } from "react"
import { Modal } from "react-bootstrap"

interface IProps {
	ShowModal: boolean
	SetShowModal: Function
	AddParticipantWithQuantity: Function
}

export const AddParticipantModal: FunctionComponent<IProps> = (props: IProps) => {
	return (
		<Modal show={props.ShowModal} backdrop="static">
			<Modal.Header></Modal.Header>
			<Modal.Body>
				<div className="section--white section__modal">
					<p className="headline__lg">
						Teilnehmeranzahl <span className="headline__part">Erhöhen?</span>
					</p>
					<div className="row">
						<div className="col-12">
							<p className="text__module">
								Durch das Hinzufügen eines neuen Teilnehmers erhöhen Sie die Anzahl der ursprünglich
								gebuchten Teilnehmer. Dadurch erhöhen sich auch die Kosten für die Veranstaltung.
								Die endgültigen Kosten werden Ihnen auf der letzten Seite der Buchungsstrecke noch
								einmal angezeigt.
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<button
								className="button button__cta button--grey order__button mr-3 d-none d-lg-block float-left"
								onClick={() => props.SetShowModal(false)}
								type="reset"
							>
								<span className="">Abbrechen</span>
							</button>
							<button
								className="button button__cta button--red order__button float-right"
								onClick={() => props.AddParticipantWithQuantity()}
							>
								<span className="txt">Teilnehmeranzahl erhöhen</span>
								<i className="fas fa-arrow-right icon-fa icon--white"></i>
							</button>
							<button
								className="button button__cta button--grey order__button mr-3 d-block d-lg-none"
								onClick={() => props.SetShowModal(false)}
								type="reset"
							>
								<span className="">Abbrechen</span>
							</button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	)
}
