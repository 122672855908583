import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import { ICommercetoolsAddress } from "../../../../ts/models/billing-address";
import { IResponseModelState } from "../../../../ts/models/response-model-state";
import { validatePostcode } from "../../../../ts/functions/form-validation";
import { countryList } from "../../../../ts/constants/form-values";

interface IProps {
	editAddress: ICommercetoolsAddress;
	toggleForm: Function;
	onFormSubmit: Function;
	showHeading: boolean;
	modelStateErrors?: IResponseModelState[];
	lineItemId?: string;
	cartId?: string;
}

export const ParticipantForm: FunctionComponent<IProps> = (props: IProps) => {
	const { register, handleSubmit, errors, watch, getValues, setValue } = useForm();

	const [currentEditShippingAddress, setcurrentEditShippingAddress] = useState({
		...props.editAddress
	});
	const type = watch("shippingtype", "address");

	const [plzError, setPlZError] = useState(false);

	const country = watch("country"); // watch selcted country

	const [modelStateErrors, setModelStateErrors] = useState(props.modelStateErrors);

	useEffect(() => {
		setModelStateErrors(props.modelStateErrors);
	}, [props.modelStateErrors]);

	const validatePlz = (value: string) => {
		const isValid = validatePostcode(value, country);
		setPlZError(!isValid);
		return isValid;
	};

	function CreateApiError(key: string) {
		const error = modelStateErrors?.find((x) => x.Key === key);
		if (error !== undefined && error !== null) {
			return <div className="form-error">{error.Value[0]}</div>;
		}
	}

	function onSubmit(data: Record<string, any>) {
		props.onFormSubmit(data);
	}

	function clearApiErrors() {
		setModelStateErrors([]);
	}

	return (
		<>
			<form aria-labelledby="headline_new_participant" onSubmit={handleSubmit(onSubmit)}>
				<div className="address__form">
					<div className="row">
						{props.showHeading && (
							<div className="col-12">
								<h2 id="headline_new_participant" className="heading">
									Neuen Teilnehmer anlegen
								</h2>
								<hr />
								<legend>
									Bitte füllen Sie alle mit <span className="mandatory">*</span> gekennzeichneten
									Felder aus.
								</legend>
							</div>
						)}

						<div className="col-sm-12">
							<div className="form-group row">
								<label htmlFor="salutation_input_participant" className="col-sm-4 col-form-label">
									Anrede
									<span className="mandatory">*</span> <br />
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="salutation"
										className="form-control"
										id="salutation_input_participant"
										aria-required="true"
										ref={register({ required: true })}
									>
										<option value="" />
										<option value="F">Frau</option>
										<option value="M">Herr</option>
										<option value="D">Divers</option>
									</select>
									{errors?.salutation && (
										<div className="form-error">Bitte geben Sie eine Anrede an.</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="title_input_participant" className="col-sm-4 col-form-label">
									Titel
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="title"
										className="form-control"
										id="title_input_participant"
										placeholder="Titel"
										ref={register()}
									>
										<option />
										<option>Dr.</option>
										<option>Prof.</option>
										<option>Prof. Dr.</option>
										<option>LL. M.</option>
										<option>Ass. Jur.</option>
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="firstname_input_participant" className="col-sm-4 col-form-label">
									Vorname
									<span className="mandatory">*</span> <br />
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="firstName"
										id="firstname_input_participant"
										className="form-control"
										placeholder="Vorname"
										maxLength={30}
										aria-required="true"
										ref={register({ maxLength: 30, required: true })}
									/>
									{errors?.firstName && (
										<div className="form-error">Bitte geben Sie einen Vornamen ein.</div>
									)}
									{CreateApiError("data.FirstName")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="lastname_input_participant" className="col-sm-4 col-form-label">
									Nachname
									<span className="mandatory">*</span> <br />
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="lastName"
										id="lastname_input_participant"
										className="form-control"
										placeholder="Nachname"
										aria-required="true"
										maxLength={30}
										ref={register({ maxLength: 30, required: true })}
									/>
									{errors?.lastName && (
										<div className="form-error">Bitte geben Sie einen Nachnamen ein.</div>
									)}
									{CreateApiError("data.LastName")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="company_input_participant" className="col-sm-4 col-form-label">
									Firma
									<span className="mandatory">*</span> <br />
								</label>
								<div className="col-sm-8">
									<input
										value={currentEditShippingAddress.Id}
										ref={register()}
										type="text"
										name="id"
										hidden
									/>
									<input
										type="text"
										name="company"
										id="company_input_participant"
										className="form-control"
										placeholder="Firmenname"
										aria-required="true"
										maxLength={35}
										ref={register({ required: true })}
									/>
									{errors?.company && (
										<div className="form-error">Bitte geben Sie Ihren Firmennamen ein.</div>
									)}
									{CreateApiError("data.Company")}
									<input
										type="text"
										name="Addition1"
										aria-label="Firmenzusatz 1"
										className="form-control mt-3"
										placeholder="Firmenzusatz 1"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Addition1")}
									<input
										type="text"
										name="Addition2"
										aria-label="Firmenzusatz 2"
										className="form-control mt-3"
										placeholder="Firmenzusatz 2"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Addition2")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="department_input_participant" className="col-sm-4 col-form-label">
									Abteilung
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										name="department"
										id="department_input_participant"
										className="form-control"
										placeholder="Abteilung"
										maxLength={35}
										ref={register()}
									/>
									{CreateApiError("data.Department")}
								</div>
							</div>

							<div className="form-group row">
								<div className="col-sm-4 col-form-label">
									<label htmlFor="street_input_participant" className="col-form-label">
										Straße /&nbsp;
									</label>
									<label htmlFor="streetnumber_input_participant" className="col-form-label">
										Hausnummer <span className="mandatory">*</span>
									</label>
								</div>
								<div className="col-sm-5 col-xs-1 mobile-space">
									<input
										type="text"
										name="streetName"
										id="street_input_participant"
										className="form-control"
										placeholder="Straße"
										aria-required="true"
										maxLength={46}
										ref={register({ required: true, maxLength: 46 })}
									/>
									{errors?.streetName && (
										<div className="form-error">Bitte geben Sie die Straße ein.</div>
									)}
									{CreateApiError("data.StreetName")}
								</div>
								<div className="col-sm-3 col-xs-1 mobile-space">
									<input
										type="text"
										name="streetNumber"
										id="streetnumber_input_participant"
										className="form-control"
										placeholder="Hausnummer"
										aria-required="true"
										ref={register({ required: true })}
									/>
									{errors?.streetNumber && (
										<div className="form-error">Bitte geben Sie die Hausnummer ein.</div>
									)}
									{CreateApiError("data.StreetNumber")}
								</div>
							</div>

							<div className="form-group row">
								<div className="col-sm-4">
									<label htmlFor="plz_input_participant" className="col-form-label">
										PLZ /&nbsp;
									</label>
									<label htmlFor="city_input_participant" className="col-form-label">
										Ort<span className="mandatory">*</span>
									</label>
								</div>
								<div className="col-sm-3 col-xs-1 mobile-space">
									<input
										type="text"
										name="postalCode"
										id="plz_input_participant"
										className="form-control"
										placeholder="PLZ"
										aria-required="true"
										ref={register({ required: true, validate: validatePlz })}
									/>
									{errors?.postalCode && (
										<div className="form-error">Bitte geben Sie eine valide PLZ ein.</div>
									)}
									{CreateApiError("data.PostalCode")}
								</div>
								<div className="col-sm-5 col-xs-1 mobile-space">
									<input
										type="text"
										name="city"
										id="city_input_participant"
										className="form-control"
										placeholder="Ort"
										aria-required="true"
										ref={register({ required: true })}
									/>
									{errors?.city && <div className="form-error">Bitte geben Sie den Ort ein.</div>}
									{CreateApiError("data.City")}
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="country_input_participant" className="col-sm-4 col-form-label">
									Land <span className="mandatory">*</span>
								</label>
								<div className="col-md-5 col-sm-8">
									<select
										name="country"
										className="form-control"
										id="country_input_participant"
										placeholder=""
										aria-required="true"
										ref={register({ required: true })}
									>
										{countryList.map((countryItem) => (
											<option value={countryItem.value}>{countryItem.name}</option>
										))}
									</select>
									{errors?.country && (
										<div className="form-error">Bitte wählen Sie Ihr Land aus.</div>
									)}
								</div>
							</div>
							<div className="form-group row">
								<label
									htmlFor="email_input_participant"
									className="col-sm-4 col-form-label form__label"
								>
									E-Mail-Adresse <sup className="mandatory--sup">*</sup>
								</label>
								<div className="col-sm-8">
									<input
										className="form-control input__standard"
										id="email_input_participant"
										name="email"
										type="email"
										aria-required="true"
										maxLength={60}
										placeholder=" E-Mail-Adresse"
										ref={register({
											required: true,
											pattern: /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/
										})}
									/>
									{errors.email && (
										<div className="form-error">Bitte geben Sie Ihre E-Mail ein.</div>
									)}
									<input value={props.cartId} ref={register()} type="text" name="cartId" hidden />
									<input
										value={props.lineItemId}
										ref={register()}
										type="text"
										name="lineItemId"
										hidden
									/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="phone_input_participant" className="col-sm-4 col-form-label">
									Telefon
								</label>
								<div className="col-sm-8">
									<input
										type="text"
										id="phone_input_participant"
										className="form-control"
										placeholder="Telefon"
										name="phone"
										ref={register()}
									/>
									{CreateApiError("data.Phone")}
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 col-md-4" />
						<div className="col-12 col-md-8 text-right">
							<button
								className="button button__cta button--grey order__button mr-3 d-none d-md-inline-block"
								onClick={() => props.toggleForm()}
								type="button"
							>
								<span className="">Abbrechen</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
							<button
								className="button button__cta button--red order__button"
								onClick={clearApiErrors}
								type="submit"
							>
								<span className="">
									{props.editAddress != null ? "Speichern" : "Neuen Teilnehmer anlegen"}
								</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
							<button
								className="button button__cta button--grey order__button mr-3 d-inline-block d-md-none"
								onClick={() => props.toggleForm()}
								type="button"
							>
								<span className="">Abbrechen</span>
								<i className="fas fa-arrow-right icon-fa icon--white" />
							</button>
						</div>
					</div>
				</div>
			</form>
		</>
	);
};
